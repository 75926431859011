<template>
  <b-row>
    <b-col class="mx-auto" cols="12" md="4">
      <b-card title="Join Meeting">
        <b-row>
          <b-col cols="12" md="12">
            <div v-if="message" class="alert alert-danger p-1">{{ message }}</div>
          </b-col>
          <b-col class="form-group" cols="12" md="12">
            <label for="fullName">Enter your name to join the meeting</label>
            <input type="text" class="form-control" v-model="fullName" />
          </b-col>
          <b-col cols="12" md="12">
            <button
              @click="joinMeeting"
              :disabled="isLoading || fullName == ''"
              class="btn btn-block btn-primary"
            >
              {{ isLoading ? "Joining..." : "Join Meeting" }}
            </button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import axios from "axios";
import { BRow, BCol, BForm, BFormInput, BCard, BButton } from "bootstrap-vue";
export default {
  name: "Live",
  data() {
    return {
      username: "",
      fullName: "",
      isLoading: false,
      message: "",
    };
  },
  methods: {
    async joinMeeting() {
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append("fullName", this.fullName);
        formData.append("meetingID", this.username);
        const { data } = await axios.post("/meetings/join", formData);
        window.location = data.url;
      } catch (e) {
        this.isLoading = false;
        this.message = e.response.data.message || "Unable to Join please try again";
      }
    },
  },
  created() {
    this.username = this.$route.params.username;
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BCard,
    BButton,
  },
};
</script>
